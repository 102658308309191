import {onFind} from "@elements/init-modules-in-scope";

/**
 * Default selectors for the country vat check module
 * @type {{base: string, target: string}}
 */
const defaultSelectors = {
    base: '.js-country-vat-check',
    target: '.js-country-vat-check-target',
};

/**
 * Default options for the country vat check module
 * @type {{noVatCountryIso: string[]}}
 */
const defaultOptions = {
    noVatCountryIso: [
        'GB',
        'CH'
    ]
}

/**
 * Initialize the country vat check module and bind the event listener to the select field
 * @param {Object} selectors
 * @param {Object} options
 */
export function init(selectors = defaultSelectors, options = defaultOptions) {

    onFind(selectors.base, function (selectField) {
       selectField.addEventListener('change', onSelectChange)
    });

}

/**
 * Show or hide the vat field based on the selected country. Also set the required and disabled attributes depending on
 * the selected country.
 * @param {Event} e
 */
const onSelectChange = (e) => {
    const vatFieldWrapper = document.querySelector(defaultSelectors.target)
    const vatField = vatFieldWrapper.querySelector('input')

    if (!vatField) return

    if (defaultOptions.noVatCountryIso.includes(e.target.value)) {
        vatField.value = '';
        vatField.required = false;
        vatField.disabled = true;
        vatFieldWrapper.classList.add('hidden');
    } else {
        vatField.required = true;
        vatField.disabled = false;
        vatFieldWrapper.classList.remove('hidden');
    }
}